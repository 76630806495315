<template>
  <div class="content">
    <div v-if="isLoading" class="flex flex-col flex-1 gap-5 sm:p-2">
      <div class="flex flex-col flex-1 gap-3">
        <div class="w-full bg-gray-200 animate-pulse h-14 rounded-2xl"></div>
        <div class="w-full h-3 bg-gray-200 animate-pulse rounded-2xl"></div>
        <div class="w-full h-3 bg-gray-200 animate-pulse rounded-2xl"></div>
        <div class="w-full h-3 bg-gray-200 animate-pulse rounded-2xl"></div>
        <div class="w-full h-3 bg-gray-200 animate-pulse rounded-2xl"></div>
      </div>
      <div class="flex flex-col flex-1 gap-3">
        <div class="w-full bg-gray-200 animate-pulse h-14 rounded-2xl"></div>
        <div class="w-full h-3 bg-gray-200 animate-pulse rounded-2xl"></div>
        <div class="w-full h-3 bg-gray-200 animate-pulse rounded-2xl"></div>
        <div class="w-full h-3 bg-gray-200 animate-pulse rounded-2xl"></div>
        <div class="w-full h-3 bg-gray-200 animate-pulse rounded-2xl"></div>
      </div>
    </div>
    <div v-else class="md-layout">
      <div
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
      >
      <div class="w-full text-end">
        <router-link class="m-auto md-raised mr-5 mb-3" :to="{ name: 'Sous Catégories / Nouveau' }">
          <md-button
          class="md-raised"
            style="background-color: #1476b5 !important"
            >Nouveau</md-button
          >
        </router-link>
</div>
<!--        <table v-if="!isLoading" id="example" class="min-w-full leading-normal">-->
<!--      <thead>-->
<!--      <tr>-->
<!--        <th-->
<!--            scope="col"-->
<!--            class="px-5 py-3 text-sm font-normal text-left text-gray-800 uppercase bg-white border-b border-gray-200"-->
<!--        >-->
<!--          Image-->
<!--        </th>-->
<!--        <th-->
<!--            scope="col"-->
<!--            class="px-5 py-3 text-sm font-normal text-left text-gray-800 uppercase bg-white border-b border-gray-200"-->
<!--        >-->
<!--          Nom en fr-->
<!--        </th>-->
<!--        <th-->
<!--            style="-->
<!--                          @media screen and (max-width: 480px) {-->
<!--                            display: none;-->
<!--                          }-->
<!--                        "-->
<!--            scope="col"-->
<!--            class="px-5 py-3 text-sm font-normal text-left text-gray-800 uppercase bg-white border-b border-gray-200"-->
<!--        >-->
<!--          Nom en ar-->
<!--        </th>-->
<!--        <th-->
<!--            scope="col"-->
<!--            class="px-5 py-3 text-sm font-normal text-left text-gray-800 uppercase bg-white border-b border-gray-200"-->
<!--        >-->
<!--          Action-->
<!--        </th>-->
<!--      </tr>-->
<!--      </thead>-->
<!--      <tbody>-->
<!--      <tr v-for="subcategory in subCategories" :key="subcategory.id">-->
<!--        <td-->
<!--            style="width: 200px; margin-right: 100px !important"-->
<!--            class="px-5 py-5 text-sm bg-white border-b border-gray-200"-->
<!--        >-->
<!--          <div class="flex items-center">-->
<!--            <div class="flex-shrink-0">-->
<!--              <a href="#" class="relative block">-->
<!--                <img-->
<!--                    alt="profil"-->
<!--                    :src="subcategory.image_url"-->
<!--                    class="mx-auto object-cover rounded-full"-->
<!--                    style="-->
<!--                                  width: 100px !important;-->
<!--                                  height: 100px !important;-->
<!--                                "-->
<!--                />-->
<!--              </a>-->
<!--            </div>-->
<!--          </div>-->
<!--        </td>-->
<!--        <td-->
<!--            class="px-5 py-5 text-sm bg-white border-b border-gray-200"-->
<!--        >-->
<!--          <p class="text-gray-900 whitespace-no-wrap">-->
<!--            {{ subcategory.name_scat_fr }}-->
<!--          </p>-->
<!--        </td>-->
<!--        <td-->
<!--            style="-->
<!--                          @media screen and (max-width: 480px) {-->
<!--                            display: none;-->
<!--                          }-->
<!--                        "-->
<!--            class="px-5 py-5 text-sm bg-white border-b border-gray-200"-->
<!--        >-->
<!--          <p class="text-gray-900 whitespace-no-wrap">-->
<!--            {{ subcategory.name_scat_ar ?? "Vide" }}-->
<!--          </p>-->
<!--        </td>-->
<!--        <td-->
<!--            class=" px-5 py-5 text-sm bg-white border-b border-gray-200"-->
<!--        >-->
<!--          <div style="display: flex;align-content: center">-->
<!--            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">-->
<!--              <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>-->
<!--              <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>-->
<!--            </svg>-->

<!--            <svg @click="changeDeleteModalState(subcategory.id)" :id="subcategory.id" style="cursor: pointer"  xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="delete ml-3 bi bi-trash2-fill" viewBox="0 0 16 16">-->
<!--              <path d="M2.037 3.225A.703.703 0 0 1 2 3c0-1.105 2.686-2 6-2s6 .895 6 2a.702.702 0 0 1-.037.225l-1.684 10.104A2 2 0 0 1 10.305 15H5.694a2 2 0 0 1-1.973-1.671L2.037 3.225zm9.89-.69C10.966 2.214 9.578 2 8 2c-1.58 0-2.968.215-3.926.534-.477.16-.795.327-.975.466.18.14.498.307.975.466C5.032 3.786 6.42 4 8 4s2.967-.215 3.926-.534c.477-.16.795-.327.975-.466-.18-.14-.498-.307-.975-.466z"/>-->
<!--            </svg>-->
<!--          </div>-->
<!--        </td>-->
<!--      </tr>-->
<!--      </tbody>-->
<!--    </table>-->
         <md-card>
          <md-card-header  style="background-color: #e0622a !important">
            <h4 class="title">Liste des sous catégories</h4>
          </md-card-header>
          <md-card-content>

            <div>
              <md-table style="text-align: center" v-model="subCategories" table-header-color="orange">
                <md-table-row slot="md-table-row" slot-scope="{ item }">
                  <md-table-cell md-label="Nom en fr">{{
                    item.name_scat_fr ?? "Vide"
                  }}</md-table-cell>
                  <md-table-cell md-label="Nom en ar">{{
                    item.name_scat_fr ?? "Vide"
                  }}</md-table-cell>
                  <md-table-cell md-label="Catégorie">{{
                    item.category.name_cat_fr ?? item.category.name_cat_ar
                  }}</md-table-cell>
                  <md-table-cell md-label="Image"
                    ><img class="mx-auto"
                      style="height: 100px !important; width: 100px !important"
                      :src="item.image_url? item.image_url : non_image "
                  /></md-table-cell>
                  <md-table-cell class="items-center " md-label="Action">
                    <router-link class="m-auto" :to="`/subcategories/update/${item.id}`">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      style="color:black"

                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-pencil-square mx-auto"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"
                      />
                      <path
                        fill-rule="evenodd"
                        d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                      />
                    </svg>

                  </router-link>
                    <svg
                      @click="changeDeleteModalState(item.id)"
                      :id="item.id"
                      style="cursor: pointer"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="delete ml-3 bi bi-trash2-fill mx-auto"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="M2.037 3.225A.703.703 0 0 1 2 3c0-1.105 2.686-2 6-2s6 .895 6 2a.702.702 0 0 1-.037.225l-1.684 10.104A2 2 0 0 1 10.305 15H5.694a2 2 0 0 1-1.973-1.671L2.037 3.225zm9.89-.69C10.966 2.214 9.578 2 8 2c-1.58 0-2.968.215-3.926.534-.477.16-.795.327-.975.466.18.14.498.307.975.466C5.032 3.786 6.42 4 8 4s2.967-.215 3.926-.534c.477-.16.795-.327.975-.466-.18-.14-.498-.307-.975-.466z"
                      />
                    </svg>
                  </md-table-cell>
                </md-table-row>
              </md-table>
            </div>
          </md-card-content>
        </md-card>
      </div>

<!--      <table v-if="!isLoading" id="example" class="min-w-full leading-normal">-->
<!--        <thead>-->
<!--        <tr>-->
<!--          <th-->
<!--              scope="col"-->
<!--              class="px-5 py-3 text-sm font-normal text-left text-gray-800 uppercase bg-white border-b border-gray-200"-->
<!--          >-->
<!--            Image-->
<!--          </th>-->
<!--          <th-->
<!--              scope="col"-->
<!--              class="px-5 py-3 text-sm font-normal text-left text-gray-800 uppercase bg-white border-b border-gray-200"-->
<!--          >-->
<!--            Nom en fr-->
<!--          </th>-->
<!--          <th-->
<!--              style="-->
<!--                          @media screen and (max-width: 480px) {-->
<!--                            display: none;-->
<!--                          }-->
<!--                        "-->
<!--              scope="col"-->
<!--              class="px-5 py-3 text-sm font-normal text-left text-gray-800 uppercase bg-white border-b border-gray-200"-->
<!--          >-->
<!--            Nom en ar-->
<!--          </th>-->
<!--          <th-->
<!--              style="-->
<!--                          @media screen and (max-width: 480px) {-->
<!--                            display: none;-->
<!--                          }-->
<!--                        "-->
<!--              scope="col"-->
<!--              class="px-5 py-3 text-sm font-normal text-left text-gray-800 uppercase bg-white border-b border-gray-200"-->
<!--          >-->
<!--            Categorie-->
<!--          </th>-->
<!--          <th-->
<!--              scope="col"-->
<!--              class="px-5 py-3 text-sm font-normal text-left text-gray-800 uppercase bg-white border-b border-gray-200"-->
<!--          >-->
<!--            Action-->
<!--          </th>-->
<!--        </tr>-->
<!--        </thead>-->
<!--        <tbody>-->
<!--        <tr v-for="subcategory in subCategories" :key="subcategory.id">-->
<!--          <td-->
<!--              style="width: 200px; margin-right: 100px !important"-->
<!--              class="px-5 py-5 text-sm bg-white border-b border-gray-200"-->
<!--          >-->
<!--            <div class="flex items-center">-->
<!--              <div class="flex-shrink-0">-->
<!--                <a href="#" class="relative block">-->
<!--                  <img-->
<!--                      alt="profil"-->
<!--                      :src="subcategory.image_url"-->
<!--                      class="mx-auto object-cover rounded-full"-->
<!--                      style="-->
<!--                                  width: 100px !important;-->
<!--                                  height: 100px !important;-->
<!--                                "-->
<!--                  />-->
<!--                </a>-->
<!--              </div>-->
<!--            </div>-->
<!--          </td>-->
<!--          <td-->
<!--              class="px-5 py-5 text-sm bg-white border-b border-gray-200"-->
<!--          >-->
<!--            <p class="text-gray-900 whitespace-no-wrap">-->
<!--              {{ subcategory.name_scat_fr }}-->
<!--            </p>-->
<!--          </td>-->
<!--          <td-->
<!--              style="-->
<!--                          @media screen and (max-width: 480px) {-->
<!--                            display: none;-->
<!--                          }-->
<!--                        "-->
<!--              class="px-5 py-5 text-sm bg-white border-b border-gray-200"-->
<!--          >-->
<!--            <p class="text-gray-900 whitespace-no-wrap">-->
<!--              {{ subcategory.name_scat_ar ?? "Vide" }}-->
<!--            </p>-->
<!--          </td>-->
<!--          <td-->
<!--              style="-->
<!--                          @media screen and (max-width: 480px) {-->
<!--                            display: none;-->
<!--                          }-->
<!--                        "-->
<!--              class="px-5 py-5 text-sm bg-white border-b border-gray-200"-->
<!--          >-->
<!--            <p class="text-gray-900 whitespace-no-wrap">-->
<!--              {{ subcategory.item.category.name_cat_fr ?? subcategory.category.name_cat_ar ?? "Vide" }}-->
<!--            </p>-->
<!--          </td>-->
<!--          <td-->
<!--              class=" px-5 py-5 text-sm bg-white border-b border-gray-200"-->
<!--          >-->
<!--            <div style="display: flex;align-content: center">-->
<!--              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">-->
<!--                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>-->
<!--                <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>-->
<!--              </svg>-->

<!--              <svg @click="changeDeleteModalState(subcategory.id)" :id="subcategory.id" style="cursor: pointer"  xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="delete ml-3 bi bi-trash2-fill" viewBox="0 0 16 16">-->
<!--                <path d="M2.037 3.225A.703.703 0 0 1 2 3c0-1.105 2.686-2 6-2s6 .895 6 2a.702.702 0 0 1-.037.225l-1.684 10.104A2 2 0 0 1 10.305 15H5.694a2 2 0 0 1-1.973-1.671L2.037 3.225zm9.89-.69C10.966 2.214 9.578 2 8 2c-1.58 0-2.968.215-3.926.534-.477.16-.795.327-.975.466.18.14.498.307.975.466C5.032 3.786 6.42 4 8 4s2.967-.215 3.926-.534c.477-.16.795-.327.975-.466-.18-.14-.498-.307-.975-.466z"/>-->
<!--              </svg>-->
<!--            </div>-->
<!--          </td>-->
<!--        </tr>-->
<!--        </tbody>-->
<!--      </table>-->

      <div
        class="relative z-10"
        style="z-index: 50000"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
        :hidden="!DeleteModalOn"
      >
        <div
          class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        ></div>

        <div class="fixed inset-0 z-10 overflow-y-auto">
          <div
            class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"
          >
            <div
              class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg"
            >
              <div class="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                <div class="sm:flex sm:items-start">
                  <div
                    class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10"
                  >
                    <svg
                      class="h-6 w-6 text-red-600"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
                      />
                    </svg>
                  </div>
                  <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <h3
                      class="text-base font-semibold leading-6 text-gray-900"
                      id="modal-title"
                    >
                      Supprimer sous catégorie
                    </h3>
                    <div class="mt-2">
                      <p class="text-sm text-gray-500">
                        vous êtes sur que vous voulez supprimer cette sous
                        catégorie ?
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6"
              >
                <button
                  @click="supprimer(currentSubCategory)"
                  :class="{ 'disabled:opacity-70': isLoading }"
                  type="button"
                  class="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                  :disabled="isLoading"
                >
                  <svg
                    v-if="isLoading"
                    width="20"
                    height="20"
                    fill="currentColor"
                    class="mr-2 animate-spin"
                    viewBox="0 0 1792 1792"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M526 1394q0 53-37.5 90.5t-90.5 37.5q-52 0-90-38t-38-90q0-53 37.5-90.5t90.5-37.5 90.5 37.5 37.5 90.5zm498 206q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-704-704q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm1202 498q0 52-38 90t-90 38q-53 0-90.5-37.5t-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-964-996q0 66-47 113t-113 47-113-47-47-113 47-113 113-47 113 47 47 113zm1170 498q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-640-704q0 80-56 136t-136 56-136-56-56-136 56-136 136-56 136 56 56 136zm530 206q0 93-66 158.5t-158 65.5q-93 0-158.5-65.5t-65.5-158.5q0-92 65.5-158t158.5-66q92 0 158 66t66 158z"
                    ></path>
                  </svg>
                  Supprimer
                </button>
                <button
                  @click="changeDeleteModalState(null)"
                  type="button"
                  class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                >
                  Annuler
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--      <div-->
      <!--        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"-->
      <!--      >-->
      <!--        <md-card class="md-card-plain">-->
      <!--          <md-card-header data-background-color="green">-->
      <!--            <h4 class="title">Table on Plain Background</h4>-->
      <!--            <p class="category">Here is a subtitle for this table</p>-->
      <!--          </md-card-header>-->
      <!--          <md-card-content>-->
      <!--            <ordered-table></ordered-table>-->
      <!--          </md-card-content>-->
      <!--        </md-card>-->
      <!--      </div>-->
    </div>
  </div>
</template>

<script>
import { SimpleTable, OrderedTable } from "@/components";
import { store } from "../../store/modules/GeneralStore.js";
import $ from "jquery";
import jQuery from "jquery";
import DataTable from 'datatables.net-dt';
import "datatables.net-dt/css/jquery.dataTables.min.css"
import "datatables.net-dt/css/jquery.dataTables.css"
export default {
  components: {
    //OrderedTable,
    //SimpleTable,
  },
  data() {
    return {
      DeleteModalOn: false,
      currentSubCategory: null,
      non_image: require("@/assets/img/null.png"),

    };
  },
  computed: {
    isLoading() {
      return store.state.isLoading;
    },
    AnyErrors() {
      return store.state.error;
    },
    AnySuccess() {
      return store.state.success;
    },
    subCategories() {
      return store.state.subCategories;
    },
  },
  mounted: function () {
    store.dispatch("getSubCategories").then(
        function () {
          console.log("data ready");
          new DataTable('#example').draw();
        }
    );
  },
  methods: {
    changeDeleteModalState(scat_id) {
      this.DeleteModalOn = !this.DeleteModalOn;
      this.currentSubCategory = scat_id;
    },
    supprimer(scat_id) {
      let context = this;
      this.DeleteModalOn = !this.DeleteModalOn;
      store.dispatch("deleteSbCategories", scat_id).then(
          function () {
            if (sessionStorage.getItem("subCategorySuccess")){
              context.$toasted.success(sessionStorage.getItem("subCategorySuccess"),{
                duration: 5000,
                position: 'top-right', // ['top-right', 'top-center', 'top-left', 'bottom-right', 'bottom-center', 'bottom-left']
                theme: 'bubble', // ['toasted-primary', 'outline', 'bubble']
                iconPack: 'mdi', // ['material', 'fontawesome', 'mdi', 'custom-class', 'callback']
              });
              sessionStorage.removeItem("subCategorySuccess");
            }
            if (sessionStorage.getItem("subCategoryError")){
              context.$toasted.error(sessionStorage.getItem("subCategoryError"),{
                duration: 5000,
                position: 'top-right', // ['top-right', 'top-center', 'top-left', 'bottom-right', 'bottom-center', 'bottom-left']
                theme: 'bubble', // ['toasted-primary', 'outline', 'bubble']
                iconPack: 'mdi', // ['material', 'fontawesome', 'mdi', 'custom-class', 'callback']
              });
              sessionStorage.removeItem("subCategoryError");
            }
          }
      );
    },
  },
};
</script>
