<style>
select[name="example_length"]{
  padding-right: 18px!important;
}
.dataTables_length{
  padding-bottom: 20px!important;
}
</style>
<template>
  <div class="content">
    <div v-if="isLoading" class="flex flex-col flex-1 gap-5 sm:p-2">
      <div class="flex flex-col flex-1 gap-3">
        <div class="w-full bg-gray-200 animate-pulse h-14 rounded-2xl"></div>
        <div class="w-full h-3 bg-gray-200 animate-pulse rounded-2xl"></div>
        <div class="w-full h-3 bg-gray-200 animate-pulse rounded-2xl"></div>
        <div class="w-full h-3 bg-gray-200 animate-pulse rounded-2xl"></div>
        <div class="w-full h-3 bg-gray-200 animate-pulse rounded-2xl"></div>
      </div>

      <div class="flex flex-col flex-1 gap-3">
        <div class="w-full bg-gray-200 animate-pulse h-14 rounded-2xl"></div>
        <div class="w-full h-3 bg-gray-200 animate-pulse rounded-2xl"></div>
        <div class="w-full h-3 bg-gray-200 animate-pulse rounded-2xl"></div>
        <div class="w-full h-3 bg-gray-200 animate-pulse rounded-2xl"></div>
        <div class="w-full h-3 bg-gray-200 animate-pulse rounded-2xl"></div>
      </div>
    </div>

    <div v-else class="md-layout">
      <div
          class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
      >


        <div
            style="padding: unset !important"
            class="container col-12 w-100 bg-danger"
        >
          <div class="w-100" style="width: 100% !important">
            <div
                class="flex flex-row justify-between w-full w-100 mb-1 sm:mb-0"
                style="width: 100% !important"
            >
              <h2 class="text-2xl leading-tight"></h2>
          
            </div>
            <div class="px-4 py-4 -mx-4 overflow-x-auto sm:-mx-8 sm:px-8">
              <div
                  class="inline-block min-w-full overflow-hidden rounded-lg shadow"
              >
               
              </div>
            </div>
          </div>
        </div>
      </div>



   
      <md-card>
        <md-card-header data-background-color="orange">
          <h4 class="title">Liste des produits à confirmer</h4>
        </md-card-header>
        <md-card-content>
          <!--            <simple-table :tableData="categories" ></simple-table>-->
          <div>
            <md-table style="text-align: center" v-model="unconfirmedProducts" table-header-color="orange">
              <md-table-row slot="md-table-row" slot-scope="{ item }">
                <md-table-cell md-label="Image">
                  <img style="height: 100px!important;width: 100px!important;" :src="item.image?item.image.original_url:non_image"/>
                </md-table-cell>
                <md-table-cell md-label="Nom">{{
                    item.name_product
                  }}</md-table-cell>
                <md-table-cell md-label="Type">{{
                    item.type_price
                  }}</md-table-cell>
                <md-table-cell md-label="Qte en stock">{{
                    item.qte_stock
                  }}</md-table-cell>
                <md-table-cell md-label="Qte min vente">{{
                    item.qte_min_vente??"Vide"
                  }}</md-table-cell>
                <md-table-cell md-label="Sous categorie">{{
                    item.sub_category!==null?item.sub_category.name_scat_fr:"Sans categorie"
                  }}</md-table-cell>
                <md-table-cell md-label="Marque">{{
                    item.brand!==null?item.brand.brand_name:"Vide"
                  }}</md-table-cell>
                <md-table-cell md-label="Createur">{{
                    item.creator.name
                  }}</md-table-cell>
                <md-table-cell class="items-center "  md-label="Action">
                    <md-button
                    style="background-color: #1476b5 !important"
                    @click="Confirm(item.id)" class="md-dense md-raised md-primary m-auto mr-1.5" >
                      Confirmer</md-button>
                 
                  <md-button
                  style="background-color: #e0622a  !important"
                    @click="Refuse(item.id)" class="md-dense md-raised md-primary m-auto">
                    Refuser</md-button>
                </md-table-cell>
              </md-table-row>
            </md-table>
          </div>
        </md-card-content>
      </md-card>

    </div>






  </div>
</template>

<script>
import { store } from "../../store/modules/GeneralStore.js";
import $ from "jquery";
import jQuery from "jquery";
import DataTable from 'datatables.net-dt';
import "datatables.net-dt/css/jquery.dataTables.min.css"
import "datatables.net-dt/css/jquery.dataTables.css"


export default {
  components: {
  
  },
  data() {
    return {
      
      currentProduct: null,
      non_image: require("@/assets/img/null.png"),
    };
  },
  computed: {
    isLoading() {
      return store.state.isLoading;
    },
    AnyErrors() {
      return store.state.error;
    },
    AnySuccess() {
      return store.state.success;
    },
    unconfirmedProducts() {
      return store.state.unconfirmedProducts;
    },
  },
  mounted: function () {
    let context = this;
      if (sessionStorage.getItem("productSuccess")){
        context.$toasted.success(sessionStorage.getItem("productSuccess"),{
          duration: 5000,
          position: 'top-right', // ['top-right', 'top-center', 'top-left', 'bottom-right', 'bottom-center', 'bottom-left']
          theme: 'bubble', // ['toasted-primary', 'outline', 'bubble']
          iconPack: 'mdi', // ['material', 'fontawesome', 'mdi', 'custom-class', 'callback']
        });
        sessionStorage.removeItem("productSuccess");
      }
    if (sessionStorage.getItem("productError")){
      context.$toasted.error(sessionStorage.getItem("productError"),{
        duration: 5000,
        position: 'top-right', // ['top-right', 'top-center', 'top-left', 'bottom-right', 'bottom-center', 'bottom-left']
        theme: 'bubble', // ['toasted-primary', 'outline', 'bubble']
        iconPack: 'mdi', // ['material', 'fontawesome', 'mdi', 'custom-class', 'callback']
      });
      sessionStorage.removeItem("productError");
    }
    store.dispatch("getUnconfirmedProducts").then(
        function () {
         
        }
    );
  },
  methods: {
    Confirm(product_id){
      console.log(product_id);
      let payload  ={
          
          id : product_id
        } 
      store.dispatch("ConfirmProduct", payload).then(
        function () {
                  window.location = "/produits/non-confirmes";
          }  
      );

    },
    Refuse(product_id){
      console.log(product_id);
      
      let payload  ={
          
          id : product_id
        } 
      store.dispatch("RefuseProduct", payload).then(
        function () {
                  window.location = "/produits/non-confirmes";
                }
        );
      }

  },
};
</script>
