<style>
select[name="example_length"]{
  padding-right: 18px!important;
}
.dataTables_length{
  padding-bottom: 20px!important;
}
</style>
<template>
  <div class="content">
    <div v-if="isLoading " class="flex flex-col flex-1 gap-5 sm:p-2">
      <div class="flex flex-col flex-1 gap-3">
        <div class="w-full bg-gray-200 animate-pulse h-14 rounded-2xl"></div>
        <div class="w-full h-3 bg-gray-200 animate-pulse rounded-2xl"></div>
        <div class="w-full h-3 bg-gray-200 animate-pulse rounded-2xl"></div>
        <div class="w-full h-3 bg-gray-200 animate-pulse rounded-2xl"></div>
        <div class="w-full h-3 bg-gray-200 animate-pulse rounded-2xl"></div>
      </div>

      <div class="flex flex-col flex-1 gap-3">
        <div class="w-full bg-gray-200 animate-pulse h-14 rounded-2xl"></div>
        <div class="w-full h-3 bg-gray-200 animate-pulse rounded-2xl"></div>
        <div class="w-full h-3 bg-gray-200 animate-pulse rounded-2xl"></div>
        <div class="w-full h-3 bg-gray-200 animate-pulse rounded-2xl"></div>
        <div class="w-full h-3 bg-gray-200 animate-pulse rounded-2xl"></div>
      </div>
    </div>

    <div v-else class="md-layout">
      <div
          class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
      >

      <div class="w-full text-end">
      
      <router-link class="md-raised mr-5 " :to="{ name: 'Produits / Nouveau Produit' }">
        <md-button
            class="md-raised"
            style="background-color: #1476b5 !important"
        >Nouveau</md-button
        >
      </router-link>
     
        <md-button @click="hideImportModal"
        class="md-raised mr-5 "
            style="background-color: #e0622a !important"
        >Importer</md-button>
      </div>
        <div
            style="padding: unset !important"
            class="container col-12 w-100 bg-danger"
        >
          <div class="w-100" style="width: 100% !important">
            <div
                class="flex flex-row justify-between w-full w-100 mb-1 sm:mb-0"
                style="width: 100% !important"
            >
              <h2 class="text-2xl leading-tight"></h2>
          
            </div>
            <div class="px-4 py-4 -mx-4 overflow-x-auto sm:-mx-8 sm:px-8">
              <div
                  class="inline-block min-w-full overflow-hidden rounded-lg shadow"
              >
               
              </div>
            </div>
          </div>
        </div>
      </div>

      
      <div class="relative z-10 " style="z-index: 50000" aria-labelledby="modal-title" role="dialog" aria-modal="true" :hidden="!importModalOn">
      
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

        <div class="fixed inset-0 z-10 overflow-y-auto">
          <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
        
            <div  class="flex items-center justify-center relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all ">
              <div class="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4" >
                <div
                    id="importProgress"
                    style="display: none"
                >

                  <div>
                    <div class=" p-4 m-auto rounded-lg shadow w-72">
                      <div>
            <span class="text-xs font-light inline-block py-1 px-2 uppercase rounded-full text-white bg-indigo-600">
                Task in progress
            </span>
                      </div>
                      <div class="w-full h-4 bg-gray-400 rounded-full mt-3">
                        <div
                            :style="{'width': ImportProgress }"
                            class=" h-full text-center text-xs text-white bg-indigo-600 rounded-full">
                          {{ImportProgress}}
                        </div>
                      </div>
                      <div v-if="AnyErrors" class="text-red-600 w-full mb-4 p-4" role="alert">
                        <p class="font-bold">
                          {{AnyErrors }}
                        </p>
                      </div>
                    </div>
                  </div>

                </div>
                <form @submit.prevent id="importForm" style="width: 450px;" class="">
                  <div   class="mt-4 pb-5 flex text-sm leading-6 text-gray-600">
                    <label
                        for="file-upload"
                        class="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                    >
                      <span class="drop-zone__prompt" id="drop-zone" @click="importProcess">Importer un fichier EXCEL </span>
                      <input
                          id="real_input"
                          name="file-upload"
                          type="file"
                          class="sr-only"
                          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                      />
                    </label>
                    <!--                  <p class="pl-1">or drag and drop</p>-->
                    </div>
                  <label style="font-weight: bold;color: black" class="pb-5">Images</label>
                  <vue-upload-multiple-image
                      class="pb-5"
                      @upload-success="uploadImageSuccess"
                      @before-remove="beforeRemove"
                      @edit-image="editImage"
                      :data-images="images"
                      dragText="Sélectionner les images de produit"
                      browseText="Naviguer"
                      primaryText="Principale"
                      markIsPrimaryText="Definir comme principale"
                      popupText="pop"
                      dropText="drop"
                      idUpload="myIdUpload"
                      editUpload="myIdEdit"
                      :maxImage=1000
                  ></vue-upload-multiple-image>
                  <button  style="display: none" id="importbtn"    :class="{'disabled:opacity-70': isLoading,}" type="submit" class=" mt-2 inline-flex w-full justify-center rounded-md bg-gray-800 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 sm:ml-3 sm:w-auto" :disabled="isLoading">
                    <svg v-if="isLoading" width="20" height="20" fill="currentColor" class="mr-2 animate-spin" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                      <path d="M526 1394q0 53-37.5 90.5t-90.5 37.5q-52 0-90-38t-38-90q0-53 37.5-90.5t90.5-37.5 90.5 37.5 37.5 90.5zm498 206q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-704-704q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm1202 498q0 52-38 90t-90 38q-53 0-90.5-37.5t-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-964-996q0 66-47 113t-113 47-113-47-47-113 47-113 113-47 113 47 47 113zm1170 498q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-640-704q0 80-56 136t-136 56-136-56-56-136 56-136 136-56 136 56 56 136zm530 206q0 93-66 158.5t-158 65.5q-93 0-158.5-65.5t-65.5-158.5q0-92 65.5-158t158.5-66q92 0 158 66t66 158z">
                      </path>
                    </svg>
                    Import</button>
                  <button  @click="hideImportModal" type="button" class="mt-3 mt-2 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto">Annuler</button>
                  <div  class="text-center" style="color: darkred;display: none" id="error_response"></div>
                </form>
              </div>
     
            </div>
          </div>
        </div>
      </div>


      <md-card>
        <md-card-header style="background-color: #e0622a !important">
          <h4 class="title">Liste des produits</h4>
        </md-card-header>
        <md-card-content>
          <div>
            <md-table style="text-align: center" v-model="sellerproducts" table-header-color="orange">
              <md-table-row slot="md-table-row" slot-scope="{ item }">
                <md-table-cell md-label="Image">
                  <img style="height: 100px!important;width: 100px!important;" :src="item.image?item.image.original_url:noe_image"/>
                </md-table-cell>
                <md-table-cell md-label="Référence TAWDAW">{{
                    item.reference_tawdaw
                  }}</md-table-cell>
                  <md-table-cell md-label="Référence du produit">{{
                    item.reference_product
                  }}</md-table-cell>
                <md-table-cell md-label="Nom">{{
                    item.name_product
                  }}</md-table-cell>
                <md-table-cell md-label="Type">{{
                    item.type_price
                  }}</md-table-cell>
                <md-table-cell md-label="Qte en stock">{{
                    item.qte_stock
                  }}</md-table-cell>
                <md-table-cell md-label="Qte min vente">{{
                    item.qte_min_vente??"Vide"
                  }}</md-table-cell>
                <md-table-cell md-label="Sous categorie">{{
                        item.sub_category!==null?item.sub_category.name_scat_fr:"Sans categorie"
                  }}</md-table-cell>
                <md-table-cell md-label="Marque">{{
                    item.brand!==null?item.brand.brand_name:"Vide"
                  }}</md-table-cell> 
                <md-table-cell md-label="Status">
                  <div v-if="item.status=='pending'">
                    <div   >
                    <p class="inline-flex rounded-full bg-[#918755] bg-opacity-10 py-1 px-3 text-sm font-medium text-yellow-500">
                           En cours
                            </p>
                  </div>
                  </div>
                  <div v-if="item.status=='confirmed'">
                    <div   >
                    <p class="inline-flex rounded-full bg-[#918755] bg-opacity-10 py-1 px-3 text-sm font-medium text-success">
                           Confirmé
                            </p>
                  </div>
                  </div>
                  <div v-if="item.status=='refused'">
                    <div   >
                    <p class="inline-flex rounded-full bg-[#918755] bg-opacity-10 py-1 px-3 text-sm font-medium text-danger">
                           Refusé
                            </p>
                  </div>

                  </div>
                 
                  </md-table-cell>
                <md-table-cell class="items-center " md-label="Action">

                  <router-link class="m-auto "  :to="`/produit/update/${item.id}`">
                    <svg
                        style="color:black"

                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-pencil-square"
                        viewBox="0 0 16 16"
                    >
                      <path
                          d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"
                      />
                      <path
                          fill-rule="evenodd"
                          d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                      />
                    </svg>
                  </router-link >

                  <svg @click="changeDeleteModalState(item.id)" :id="item.id" style="cursor: pointer"  xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="delete ml-3 bi bi-trash2-fill" viewBox="0 0 16 16">
                    <path d="M2.037 3.225A.703.703 0 0 1 2 3c0-1.105 2.686-2 6-2s6 .895 6 2a.702.702 0 0 1-.037.225l-1.684 10.104A2 2 0 0 1 10.305 15H5.694a2 2 0 0 1-1.973-1.671L2.037 3.225zm9.89-.69C10.966 2.214 9.578 2 8 2c-1.58 0-2.968.215-3.926.534-.477.16-.795.327-.975.466.18.14.498.307.975.466C5.032 3.786 6.42 4 8 4s2.967-.215 3.926-.534c.477-.16.795-.327.975-.466-.18-.14-.498-.307-.975-.466z"/>
                  </svg>
                </md-table-cell>

              </md-table-row>
            </md-table>
          </div>
        </md-card-content>
      </md-card>

    </div>

    <div
        class="relative z-10"
        style="z-index: 50000"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
        :hidden="!DeleteModalOn"
    >
      <div
          class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
      ></div>

      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div
            class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"
        >
          <div
              class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg"
          >
            <div class="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
              <div class="sm:flex sm:items-start">
                <div
                    class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10"
                >
                  <svg
                      class="h-6 w-6 text-red-600"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      aria-hidden="true"
                  >
                    <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
                    />
                  </svg>
                </div>
                <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                  <h3
                      class="text-base font-semibold leading-6 text-gray-900"
                      id="modal-title"
                  >
                    Supprimer produit
                  </h3>
                  <div class="mt-2">
                    <p class="text-sm text-gray-500">
                      vous êtes sur que vous voulez supprimer ce produit
                      ?
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
                class="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6"
            >
              <button
                  @click="supprimer(currentCategory)"
                  :class="{ 'disabled:opacity-70': isLoading }"
                  type="button"
                  class="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                  :disabled="isLoading"
              >
                <svg
                    v-if="isLoading"
                    width="20"
                    height="20"
                    fill="currentColor"
                    class="mr-2 animate-spin"
                    viewBox="0 0 1792 1792"
                    xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                      d="M526 1394q0 53-37.5 90.5t-90.5 37.5q-52 0-90-38t-38-90q0-53 37.5-90.5t90.5-37.5 90.5 37.5 37.5 90.5zm498 206q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-704-704q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm1202 498q0 52-38 90t-90 38q-53 0-90.5-37.5t-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-964-996q0 66-47 113t-113 47-113-47-47-113 47-113 113-47 113 47 47 113zm1170 498q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-640-704q0 80-56 136t-136 56-136-56-56-136 56-136 136-56 136 56 56 136zm530 206q0 93-66 158.5t-158 65.5q-93 0-158.5-65.5t-65.5-158.5q0-92 65.5-158t158.5-66q92 0 158 66t66 158z"
                  ></path>
                </svg>
                Supprimer
              </button>
              <button
                  @click="changeDeleteModalState(null)"
                  type="button"
                  class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
              >
                Annuler
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

<!-- <p>
  salam
  {{ sellerproducts[0].media.original_url }}
</p> -->

  </div>
</template>

<script>
import { store } from "../../store/modules/GeneralStore.js";
import $ from "jquery";
import jQuery from "jquery";
import DataTable from 'datatables.net-dt';
import "datatables.net-dt/css/jquery.dataTables.min.css"
import "datatables.net-dt/css/jquery.dataTables.css"
import * as XLSX from "xlsx";
import VueUploadMultipleImage from 'vue-upload-multiple-image';


export default {
  components: {
    VueUploadMultipleImage,

  },
  data() {
    return {
      images: [],
      // DeleteModalOn: false,
      DeleteModalOn: false,
      currentProduct: null,
      importModalOn: false,
      noe_image: require("@/assets/img/null.png"),
    };
  },
  computed: {
    isLoading() {
      return store.state.isLoading;
    },
    AnyErrors() {
      return store.state.error;
    },
    AnySuccess() {
      return store.state.success;
    },
    sellerproducts() {
      return store.state.sellerproducts;
    },
    ImportProgress(){
      return store.state.importProgress;
    },
  },
  mounted: function () {
    let context = this;
    if (sessionStorage.getItem("productSuccess")){
      context.$toasted.success(sessionStorage.getItem("productSuccess"),{
        duration: 5000,
        position: 'top-right', // ['top-right', 'top-center', 'top-left', 'bottom-right', 'bottom-center', 'bottom-left']
        theme: 'bubble', // ['toasted-primary', 'outline', 'bubble']
        iconPack: 'mdi', // ['material', 'fontawesome', 'mdi', 'custom-class', 'callback']
      });
      sessionStorage.removeItem("productSuccess");
    }
    if (sessionStorage.getItem("productError")){
      context.$toasted.error(sessionStorage.getItem("productError"),{
        duration: 5000,
        position: 'top-right', // ['top-right', 'top-center', 'top-left', 'bottom-right', 'bottom-center', 'bottom-left']
        theme: 'bubble', // ['toasted-primary', 'outline', 'bubble']
        iconPack: 'mdi', // ['material', 'fontawesome', 'mdi', 'custom-class', 'callback']
      });
      sessionStorage.removeItem("productError");
    }
    if (sessionStorage.getItem("productImportSuccess")){
      context.$toasted.success(sessionStorage.getItem("productImportSuccess"),{
        duration: 5000,
        position: 'top-right', // ['top-right', 'top-center', 'top-left', 'bottom-right', 'bottom-center', 'bottom-left']
        theme: 'bubble', // ['toasted-primary', 'outline', 'bubble']
        iconPack: 'mdi', // ['material', 'fontawesome', 'mdi', 'custom-class', 'callback']
      });
      sessionStorage.removeItem("productImportSuccess");
    }
    if (sessionStorage.getItem("productImportError")){
      context.$toasted.error(sessionStorage.getItem("productImportError"),{
        duration: 5000,
        position: 'top-right', // ['top-right', 'top-center', 'top-left', 'bottom-right', 'bottom-center', 'bottom-left']
        theme: 'bubble', // ['toasted-primary', 'outline', 'bubble']
        iconPack: 'mdi', // ['material', 'fontawesome', 'mdi', 'custom-class', 'callback']
      });
      sessionStorage.removeItem("productImportError");
    }

    store.dispatch("getSellerProducts").then(
        function () {
          console.log("data ready");
          console.log("this is data ", context.sellerproducts)
        }
    );

    console.log('this is id',store.state.user);

  },
  methods: {
   
    hideImportModal(){
      this.importModalOn = !this.importModalOn;
    },
    uploadImageSuccess(formData, index, fileList) {
      //console.log('data', formData, index, fileList)
      this.images = fileList;
      // //Upload image api
      // axios.post('http://your-url-upload', formData).then(response => {
      //   console.log(response)
      // })
    },
    beforeRemove (index, done, fileList) {
      done()
    },
    editImage (formData, index, fileList) {
      console.log('edit data', formData, index, fileList)
    },
    changeDeleteModalState(product_id) {
      this.DeleteModalOn = !this.DeleteModalOn;
      this.currentCategory = product_id;
    },
    supprimer(product_id) {
      let context = this;
      this.DeleteModalOn = !this.DeleteModalOn;
      store.dispatch("deleteProduct", product_id).then(
          function () {
            window.location = "/produits"
                 }
      );
    },
    importProcess(){
      let context = this;
        const real=document.getElementById("real_input");
        const fake=document.getElementById("drop-zone");
      let selectedFile;
      real.click();
      $('#real_input').change(function () {
        $('#error_response').css.display = "none";
        $('#importbtn').css("display","");
        $('.drop-zone__prompt').html(this.files.length + " file(s) selected");
        selectedFile = this.files[0];
      });


      $('#importbtn').click(function (){
        if(selectedFile) {
          store.dispatch('changeImportProgress','0%')
          $('#importForm').css("display","none");
          $('#importProgress').css("display","");
          let fileReader = new FileReader();
          fileReader.readAsBinaryString (selectedFile);
          fileReader.onload = (event) =>{
            let data = event.target.result;
            let workbook = XLSX.read(data,{type:'binary'});
            workbook.SheetNames.forEach(sheet => {
              let rowObject = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheet]);
             store.dispatch('importProduct',{rowObject:rowObject,images :context.images});
              console.log(rowObject);
            })
          }

        }
        else {
          $('#error_response').html("file is required");
          $('#error_response').css.display = "";
        }
      });

    }
  },
};
</script>
<style>
/* table's header style */
th{
  text-align: center!important;
}
.drop-zone {
  height: 200px;
  padding: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: "Quicksand", sans-serif;
  font-weight: 500;
  font-size: 20px;
  cursor: pointer;
  color: #cccccc;
  border: 4px dashed #646cff;
  border-radius: 10px;
}

.drop-zone--over {
  border-style: solid;
}

.drop-zone__input {
  display: none;
}

.drop-zone__thumb {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
  background-color: #cccccc;
  background-size: cover;
  position: relative;
}

.drop-zone__thumb::after {
  content: attr(data-label);
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 5px 0;
  color: #ffffff;
  background: rgba(0, 0, 0, 0.75);
  font-size: 14px;
  text-align: center;
}

.greencercle {
  margin-left:35%;
    width: 15px;
    height: 15px;
    border-radius: 20px;
    background: green;
}
.redcercle {
  margin-left:35%;
    width: 15px;
    height: 15px;
    border-radius: 20px;
    background: red;
}
/* .yellowcercle {
  
    width: 15px;
    height: 15px;
    border-radius: 20px;
    background: rgb(223, 223, 8);
} */
.container-yellow {
  display: flex;
  align-items: center;
}
.container-red {
  display: flex;
  align-items: center;
}
.container-green {
  display: flex;
  align-items: center;
}

.yellowcercle {
  width: 15px;
  height: 15px;
  border-radius: 20px;
  background: rgb(223, 223, 8);
}
.redcercle {
  width: 15px;
  height: 15px;
  border-radius: 20px;
  background: rgb(223, 8, 8);
}
.greencercle {
  width: 15px;
  height: 15px;
  border-radius: 20px;
  background:green;
}

.status-text {
  margin-left: 5px; /* Adjust as needed */
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .status-text {
    margin-left: 10px; /* Adjust for smaller screens */
  }
}

</style>

